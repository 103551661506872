import React, {useState, useEffect} from 'react'
import Link from 'next/link'
import {SiteLogoImage} from '../../components'

export const Header = () => {
  const [top, setTop] = useState(true)

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-95 transition duration-300 ease-in-out ${
        !top && 'bg-gray-900 blur shadow-lg'
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link href="/">
              <a className="w-11 flex items-center" aria-label="Reflect">
                <SiteLogoImage width={1024} height={1024} />
              </a>
            </Link>
          </div>

          <nav>
            <a
              href="/auth"
              className="text-base font-medium text-white hover:text-gray-300 transition duration-200"
            >
              Login
            </a>
          </nav>
        </div>
      </div>
    </header>
  )
}
