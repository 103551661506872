import React from 'react'
import AOS from 'aos'
import {Header} from './shared/header'
import {Footer} from './shared/footer'
import {HeroHome} from './home/hero'
import {useEffect} from 'react'
import {Features} from './home/features'
import Head from 'next/head'

export const Home = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
  }, [])

  return (
    <div className="site flex flex-col min-h-screen overflow-hidden bg-black text-white">
      <Head>
        <meta
          name="description"
          content="Reflect keeps track of your thoughts, books, and meetings."
        />
        <meta
          name="og:description"
          content="Reflect keeps track of your thoughts, books, and meetings."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reflectnotes" />
        <meta
          name="twitter:image"
          content="https://reflect.app/static/twitter-card.png"
        />
        <meta name="og:title" content="Reflect" />
        <meta name="og:image" content="https://reflect.app/static/twitter-card.png" />
        <meta name="og:url" content="https://reflect.app" />
        <meta name="apple-mobile-web-app-title" content="Reflect" />
      </Head>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />

        <Features />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}
