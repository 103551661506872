import React from 'react'
import Link from 'next/link'
import {SiteLogoImage} from '../../components'

export const Footer = () => {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-900">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              {/* Logo */}
              <Link href="/">
                <a className="block w-8" aria-label="Reflect">
                  <SiteLogoImage width={32} height={32} />
                </a>
              </Link>
            </div>
            <div className="text-sm text-gray-400">
              <Link href="/terms">
                <a className="text-gray-400 hover:text-gray-300 transition duration-200 ease-in-out">
                  Terms
                </a>
              </Link>
              <span> · </span>
              <Link href="/privacy">
                <a className="text-gray-400 hover:text-gray-300 transition duration-200 ease-in-out">
                  Privacy Policy
                </a>
              </Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2"></div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2"></div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2"></div>

          {/* 5th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-200 font-medium mb-2">Subscribe</h6>

            <a
              href="https://reflectchangelog.substack.com"
              className="bg-blue-600 rounded-md px-4 py-3 inline-block text-center text-sm transition duration-500 hover:bg-blue-500"
              aria-label="Subscribe"
            >
              Follow along with our progress
            </a>
          </div>
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-900">
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <Link href="https://twitter.com/reflectnotes">
                <a
                  className="flex justify-center items-center text-white hover:bg-gray-800 bg-gray-900 rounded-full shadow transition duration-200 ease-in-out"
                  aria-label="Twitter"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                  </svg>
                </a>
              </Link>
            </li>
            <li className="ml-4">
              <Link href="https://github.com/team-reflect">
                <a
                  className="flex justify-center items-center text-white hover:bg-gray-800 bg-gray-900 rounded-full shadow transition duration-200 ease-in-out"
                  aria-label="Github"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                  </svg>
                </a>
              </Link>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-gray-400 mr-4">
            Made by{' '}
            <a className="text-purple-600" href="https://reflect.app/">
              Reflect App, LLC
            </a>
            . All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}
