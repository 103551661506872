import React, {useState, useRef, useEffect} from 'react'
import {Transition} from '@headlessui/react'
import {Database, Rss, Share} from 'heroicons-react'
import Image from 'next/image'

export const Features = () => {
  const [tab, setTab] = useState(1)

  const tabs = useRef<HTMLDivElement>(null)

  const heightFix = () => {
    const tabsChildren = (tabs.current?.children || []) as HTMLElement[]

    if (tabs.current && tabsChildren[tab]) {
      tabs.current.style.height = tabsChildren[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
  }, [tab])

  return (
    <section className="relative max-w-6xl mx-auto px-4 sm:px-6" id="features">
      <div className="py-12 md:py-12 md:mb-20">
        {/* Section content */}
        <div className="md:grid md:grid-cols-12 md:gap-6">
          {/* Content */}
          <div
            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
            data-aos="fade-right"
          >
            <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
              <h3 className="text-4xl font-bold leading-tight mb-3">Tools for thought</h3>
              <p className="text-xl text-gray-600">
                Your brain was made for having ideas, not storing them. That&apos;s on us.
              </p>
            </div>
            {/* Tabs buttons */}
            <div className="mb-8 md:mb-0 space-y-5">
              <a
                className={`flex items-center text-lg p-5 rounded-lg transition duration-300 ease-in-out ${
                  tab !== 1 ? 'bg-gray-900 shadow-md hover:shadow-lg' : 'bg-gray-800'
                }`}
                href="#features-backlink"
                onClick={() => {
                  setTab(1)
                }}
              >
                <div>
                  <div className="text-xl font-bold leading-normal tracking-wide mb-1">
                    Backlink relationships
                  </div>
                  <div className="text-gray-500">
                    Build a personal knowledge graph by back-linking notes to each other,
                    modeling your brains associations.
                  </div>
                </div>
                <div className="flex justify-center items-center w-10 h-10 bg-gray-700 rounded-full flex-shrink-0 ml-3">
                  <Share className="w-5 h-5 fill-current" />
                </div>
              </a>
              <a
                className={`flex items-center text-lg p-5 rounded-lg transition duration-300 ease-in-out ${
                  tab !== 2 ? 'bg-gray-900 shadow-md hover:shadow-lg' : 'bg-gray-800'
                }`}
                href="#features-sync"
                onClick={() => {
                  setTab(2)
                }}
              >
                <div>
                  <div className="text-xl font-bold leading-normal tracking-wide mb-1">
                    Write once, sync everywhere.
                  </div>
                  <div className="text-gray-500">
                    We ensure your notes are backed up and synced to all your devices,
                    online or offline. All with end-to-end encryption.
                  </div>
                </div>
                <div className="flex justify-center items-center w-10 h-10 bg-gray-700 rounded-full flex-shrink-0 ml-3">
                  <Database className="w-5 h-5 fill-current" />
                </div>
              </a>
              <a
                className={`flex items-center text-lg p-5 rounded-lg transition duration-300 ease-in-out ${
                  tab !== 3 ? 'bg-gray-900 shadow-md hover:shadow-lg' : 'bg-gray-800'
                }`}
                href="#features-api"
                onClick={() => {
                  setTab(3)
                }}
              >
                <div>
                  <div className="text-xl font-bold leading-normal tracking-wide mb-1">
                    Kindle integration and REST API
                  </div>
                  <div className="text-gray-500">
                    We are API first and integrated into all your favorite tools.
                  </div>
                </div>
                <div className="flex justify-center items-center w-10 h-10 bg-gray-700 rounded-full flex-shrink-0 ml-3">
                  <Rss className="w-5 h-5 fill-current" />
                </div>
              </a>
            </div>
          </div>

          {/* Tabs items */}
          <div
            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
            data-aos="zoom-y-out"
            ref={tabs}
          >
            <div className="relative flex flex-col text-center lg:text-right md:ml-12 md:-top-8 mt-20 md:mt-0">
              {/* Item 1 */}
              <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterFrom="opacity-0 translate-y-16"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-16"
              >
                <Image
                  layout="fixed"
                  quality={100}
                  priority={true}
                  className="md:max-w-none absolute left-0 transform animate-float"
                  width={1114}
                  height={750}
                  src="/site/backlink-relationships.png"
                />
              </Transition>
              {/* Item 2 */}
              <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterFrom="opacity-0 translate-y-16"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-16"
              >
                <Image
                  layout="fixed"
                  quality={100}
                  priority={true}
                  className="md:max-w-none absolute left-0 transform animate-float"
                  width={1114}
                  height={750}
                  src="/site/features-sync.png"
                />
              </Transition>
              {/* Item 3 */}
              <Transition
                show={tab === 3}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterFrom="opacity-0 translate-y-16"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-16"
              >
                <Image
                  layout="fixed"
                  quality={100}
                  priority={true}
                  className="md:max-w-none absolute left-0 transform animate-float"
                  width={1114}
                  height={750}
                  src="/site/api.png"
                />
              </Transition>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden">
        <Image
          layout="fixed"
          quality={100}
          priority={true}
          width={1114}
          height={750}
          src="/site/features-sync.png"
        />
        <Image
          layout="fixed"
          quality={100}
          priority={true}
          width={1114}
          height={750}
          src="/site/backlink-relationships.png"
        />
        <Image
          layout="fixed"
          quality={100}
          priority={true}
          width={1114}
          height={750}
          src="/site/api.png"
        />
      </div>
    </section>
  )
}
