import React from 'react'
import Link from 'next/link'

export const HeroHome = () => {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-5xl md:text-8xl font-extrabold leading-tight md:leading-tight tracking-tighter mb-4 md:mb-10"
              data-aos="zoom-y-out"
            >
              Think better with
              <br />{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-pink-600">
                Reflect
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl md:text-4xl leading-8 text-gray-200 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Keep track of your notes, <br />
                books, and meetings.
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:mt-16"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <Link href="/auth">
                  <a className="btn text-white bg-purple-600 hover:bg-purple-700 shadow flex-none">
                    Sign up for free
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
